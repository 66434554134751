<template>
  <div class="dashboard-toolbar-controls">
    <vs-dropdown vs-trigger-click>
      <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium">
        <span class="mr-2 leading-none">Call Center Report</span>
        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
      </div>

      <vs-dropdown-menu>
        <vs-dropdown-item @click.prevent="onExportRequestStatistics">
          <span class="flex items-center">
            <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Export Request Statistics</span>
          </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click.prevent="onExportUserWorkTimeReport">
          <span class="flex items-center">
            <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Export Work Times </span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { commonFunctions } from '@/mixins/commonFunctions'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
export default {
  mixins: [commonFunctions],
  props: ['dateRange'],

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      callCenterReport: 'dashboard/callCenterReport',
      selectedAccount: 'selectedAccount'
    }),
    selectedAccountId() {
      return this.selectedAccount ? this.selectedAccount.id : this.activeUserInfo.company
    }
  },
  methods: {
    ...mapActions({
      updateCallCenterUserWorktime: 'dashboard/updateCallCenterUserWorktime',
      updateCallCenterCallRequests: 'dashboard/updateCallCenterCallRequests'
    }),
    stopLoading() {
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    getTimeInHHMMSS(totalSeconds) {
      return dayjs().startOf('day').add(totalSeconds, 's').format('HH:mm:ss')
    },
    onExportUserWorkTimeReport() {
      this.getCCWorkTimes()
    },
    onExportRequestStatistics() {
      this.getRequestStatistics()
    },
    formatTime(date) {
      return dayjs(date).format('HH:mm')
    },
    formatStringDate(date) {
      return dayjs(date).format('MMM D, YYYY HH:mm:ss')
    },
    generateCSVWorkTimes() {
      const headers = {
        day: 'Day',
        agentId: 'Agent Id',
        agentName: 'Agent Name',
        supervisorId: 'Supervisor Id',
        supervisorName: 'Supervisor Name',
        totalWorkTime: 'Total Work time',
        totalPausedTime: 'Total Paused time',
        totalReworkTime: 'Total Rework time',
        totalSessionDuration: 'Total Session Duration',
        startTime: 'Start Time',
        endTime: 'End Time'
      }
      const itemsFormatted = []

      this.callCenterReport.workingTimes.forEach((item) => {
        itemsFormatted.push({
          day: item.day_id ? dayjs(item.day_id, 'YYYYMMDD').format('MMM-DD-YYYY').replace(/,/g, '') : '',
          agentId: item.agent_id ? item.agent_id.replace(/,/g, '') : '',
          agentName: item.agent_name ? item.agent_name.replace(/,/g, '') : '',
          supervisorId: item.supervisor_id ? item.supervisor_id.replace(/,/g, '') : '',
          supervisorName: item.supervisor_name ? item.supervisor_name.replace(/,/g, '') : '',
          totalWorkTime: this.getTimeInHHMMSS(item.total_work_time).replace(/,/g, ''),
          totalPausedTime: this.getTimeInHHMMSS(item.total_paused_time).replace(/,/g, ''),
          totalReworkTime: this.getTimeInHHMMSS(item.total_rework_time).replace(/,/g, ''),
          totalSessionDuration: this.getTimeInHHMMSS(item.total_session_duration).replace(/,/g, ''),
          startTime: this.formatTime(item.start_time.value).replace(/,/g, ''),
          endTime: this.formatTime(item.end_time.value).replace(/,/g, '')
        })
      })

      const fileTitle = 'Working Times'
      this.exportCSVFile(headers, itemsFormatted, fileTitle)
    },

    generateCSVRequestStatistics() {
      const headers = {
        visitorId: 'Visitor Id',
        agentName: 'Agent Name',
        agentId: 'Agent Id',
        supervisorId: 'Supervisor Id',
        supervisorName: 'Supervisor Name',
        companyId: 'Company Id',
        companyName: 'Company Name',
        callCenterId: 'Call Center Id',
        callCenterName: 'Call Center Name',
        requestStartTime: 'Request Start Time',
        sessionStartTime: 'Session Start Time',
        sessionEndTime: 'Session End Time',
        sessionDuration: 'Session Duration',
        reworkTime: 'Rework Time',
        status: 'Status',
        statusType: 'Status Type'
      }
      const itemsFormatted = []

      this.callCenterReport.callRequests.forEach((item) => {
        itemsFormatted.push({
          visitorId: item.visitor_id ? item.visitor_id.replace(/,/g, '') : '',
          agentName: item.agent ? item.agent.replace(/,/g, '') : '',
          agentId: item.agent_id ? item.agent_id.replace(/,/g, '') : '',
          supervisorId: item.supervisor_id ? item.supervisor_id.replace(/,/g, '') : '',
          supervisorName: item.supervisor ? item.supervisor.replace(/,/g, '') : '',
          companyId: item.company_id ? item.company_id.replace(/,/g, '') : '',
          companyName: item.company ? item.company.replace(/,/g, '') : '',
          callCenterId: item.callcenter_id ? item.callcenter_id.replace(/,/g, '') : '',
          callCenterName: item.callcenter ? item.callcenter.replace(/,/g, '') : '',
          requestStartTime: item.request_start_time ? this.formatStringDate(item.request_start_time.value).replace(/,/g, '') : '',
          sessionStartTime: item.session_start_time ? this.formatStringDate(item.session_start_time.value).replace(/,/g, '') : '',
          sessionEndTime: item.session_end_time ? this.formatStringDate(item.session_end_time.value).replace(/,/g, '') : '',
          sessionDuration: item.session_duration ? this.getTimeInHHMMSS(item.session_duration).replace(/,/g, '') : '',
          reworkTime: item.rework_time ? this.getTimeInHHMMSS(item.rework_time).replace(/,/g, '') : '',
          status: item.status ? item.status.replace(/,/g, '') : '',
          statusType: item.status_type ? item.status_type.replace(/,/g, '') : ''
        })
      })

      const fileTitle = 'Request Statistics'
      this.exportCSVFile(headers, itemsFormatted, fileTitle)
    },

    async getCCWorkTimes() {
      this.$vs.loading()

      const config = {
        queryFilter: ` dt.company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += ` AND dt.updated BETWEEN "${dayjs
        .utc(this.dateRange.startDate)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss UTC')}" AND "${dayjs.utc(this.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')}"`

      this.updateCallCenterUserWorktime([])
      try {
        const getCCWorkTimesBQ = this.$functions.httpsCallable('getCCWorkTimesBQ')
        const rs = await getCCWorkTimesBQ({ config })
        const data = rs.data.res

        if (data && data.length > 0) {
          this.updateCallCenterUserWorktime(data)
          this.generateCSVWorkTimes()
        }
      } catch (err) {
        /* eslint-disable no-console */
        console.error(err.message)
      }
      this.stopLoading()
    },
    async getRequestStatistics() {
      this.$vs.loading()

      const config = {
        queryFilter: ` rt.callcenter_id="${this.activeUserInfo.company}"`
      }

      config.queryFilter += ` AND st.updated BETWEEN "${dayjs
        .utc(this.dateRange.startDate)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss UTC')}" AND "${dayjs.utc(this.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')}"`

      this.updateCallCenterCallRequests([])
      try {
        const getCCRequestStatisticsBQ = this.$functions.httpsCallable('getCCRequestStatisticsBQ')
        const rs = await getCCRequestStatisticsBQ({ config })
        const data = rs.data.res

        if (data && data.length > 0) {
          this.updateCallCenterCallRequests(data)
          this.generateCSVRequestStatistics()
        }
      } catch (err) {
        /* eslint-disable no-console */
        console.error(err.message)
      }
      this.stopLoading()
    }
  }
}
</script>
